<template>
  <div>
    <b-modal
      ref="modalDataPortais"
      :title="$t('Portais')"
      :cancel-title="$t('Fechar')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-title="formModeView === true && load_data_geral.forceWebsiteConfig !== 2 ? showButtonSave===false ? $t('Fechar') : $t('Guardar') : $t('Seguinte')"
      :ok-only="showButtonSave===false"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="formModeView === true && load_data_geral.forceWebsiteConfig !== 2 ? showButtonSave===false ? closeModal : confirmSaveModal($event) : confirmNextModal($event)"
      @cancel="closeModal"
    >
      <b-row>
        <b-col md="12">
          <div class="d-flex justify-content-start">
            <h4 class="mr-1">
              {{ $t('Zome') }}
            </h4>
            <b-link
              v-if="showUrlWebsite === true"
              :href="load_data_geral.urlWebsite"
              target="_blank"
            >
              {{ $t('Ver') }}
            </b-link>
          </div>

          <component
            :is="blockWebsite"
            ref="website"
            :form-data="form_data"
            :current-user="currentUser"
            :mode-form="formModeView"
            :dias-vendido="load_data_geral.diasVendido"
            :url-website="load_data_geral.urlWebsite"
            :get-criterios="getCriterios"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="configApp.bUseWebsiteLuxury === true"
        class="mt-1"
      >
        <b-col md="12">
          <div class="d-flex justify-content-start">
            <h4 class="mr-1">
              {{ $t('Zome Luxury') }}
            </h4>
            <b-link
              v-if="showUrlLuxury === true"
              :href="load_data_geral.urlWebsiteLuxury"
              target="_blank"
            >
              {{ $t('Ver') }}
            </b-link>
          </div>

          <component
            :is="blockLuxury"
            ref="luxury"
            :form-data="form_data"
            :current-user="currentUser"
            :mode-form="formModeView"
            :dias-vendido="load_data_geral.diasVendido"
            :url-website="load_data_geral.urlWebsiteLuxury"
            :get-criterios="getCriterios"
            :active="load_data_geral.bLuxurySelectable"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="iso === 'PT'"
        class="mt-1"
      >
        <b-col md="12">
          <div class="d-flex justify-content-start">
            <h4 class="mr-1">
              {{ $t('CryptoHouses') }}
            </h4>
            <b-link
              v-if="showUrlCryptos === true"
              :href="load_data_geral.urlWebsiteCryptos"
              target="_blank"
            >
              {{ $t('Ver') }}
            </b-link>
          </div>

          <component
            :is="blockCryptos"
            ref="cryptos"
            :form-data="form_data"
            :current-user="currentUser"
            :mode-form="formModeView"
            :dias-vendido="load_data_geral.diasVendido"
            :url-website="load_data_geral.urlWebsiteCryptos"
            :get-criterios="getCriterios"
            :active="load_data_geral.bCryptoSelectable"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="iso === 'PT'"
        class="mt-1"
      >
        <b-col md="12">
          <div class="d-flex justify-content-start">
            <h4 class="mr-1">
              {{ $t('Imovirtual') }}
            </h4>
            <b-link
              v-if="showUrlImovirtual === true"
              :href="load_data_geral.urlWebsiteImovirtual"
              target="_blank"
            >
              {{ $t('Ver') }}
            </b-link>
          </div>

          <component
            :is="blockImovirtual"
            ref="imovirtual"
            :form-data="form_data"
            :current-user="currentUser"
            :mode-form="formModeView"
            :url-website="load_data_geral.urlWebsiteImovirtual"
            :estado="load_data_geral.stateImovirtual"
            :imovirtual-error="load_data_geral.imovirtualError"
            :imovirtual-moderate-message="load_data_geral.imovirtualModerateMessage"
            :portals="portals"
            :get-criterios="getCriterios"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="iso === 'PT'"
        class="mt-1"
      >
        <b-col md="12">
          <h4>
            {{ $t('eGO') }}
          </h4>

          <component
            :is="blockEgo"
            ref="ego"
            :form-data="form_data"
            :current-user="currentUser"
            :mode-form="formModeView"
            :portals="portals"
            :get-criterios="getCriterios"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="iso === 'ES'"
        class="mt-1"
      >
        <b-col md="12">
          <h4>
            {{ $t('Inmovilla') }}
          </h4>

          <component
            :is="blockInmovilla"
            ref="inmovilla"
            :form-data="form_data"
            :current-user="currentUser"
            :mode-form="formModeView"
            :portals="portals"
            :get-criterios="getCriterios"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="['PT', 'ES'].includes(iso)"
        class="mt-1"
      >
        <b-col md="12">
          <h4>
            {{ $t('Idealista') }}
          </h4>

          <component
            :is="blockIdealista"
            ref="idealista"
            :form-data="form_data"
            :current-user="currentUser"
            :mode-form="formModeView"
            :portals="portals"
            :get-criterios="getCriterios"
            :idealista-error-message="load_data_geral.idealistaError"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="['PT', 'ES'].includes(iso)"
        class="mt-1"
      >
        <b-col md="12">
          <div class="d-flex justify-content-start">
            <h4 class="mr-1">
              {{ $t('Listglobally') }}
            </h4>
            <b-link
              v-if="showUrlListglobally === true"
              :href="load_data_geral.urlWebsiteListglobally"
              target="_blank"
            >
              {{ $t('Ver') }}
            </b-link>
          </div>

          <component
            :is="blockListglobally"
            ref="listglobally"
            :form-data="form_data"
            :current-user="currentUser"
            :mode-form="formModeView"
            :url-website="load_data_geral.urlWebsiteListglobally"
            :portals="portals"
            :get-criterios="getCriterios"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="iso === 'PT'"
        class="mt-1"
      >
        <b-col md="12">
          <div class="d-flex justify-content-start">
            <h4 class="mr-1">
              {{ $t('Casa Yes') }}
            </h4>
            <b-link
              v-if="showUrlCasayes === true"
              :href="load_data_geral.urlWebsiteCasayes"
              target="_blank"
            >
              {{ $t('Ver') }}
            </b-link>
          </div>

          <component
            :is="blockCasaYes"
            ref="casayes"
            :form-data="form_data"
            :current-user="currentUser"
            :mode-form="formModeView"
            :portals="portals"
            :url-website="load_data_geral.urlWebsiteCasayes"
            :get-criterios="getCriterios"
            :casayes-error-message="load_data_geral.casayesError"
          />
        </b-col>
      </b-row>

    </b-modal>

    <component
      :is="ModalCriteriosWebsite"
      ref="modalCriteriosWebsite"
    />

    <component
      :is="ModalCriteriosLuxury"
      ref="modalCriteriosLuxury"
      :iso="iso"
      :criterios="criteriosLuxury"
    />

    <component
      :is="ModalCriteriosCryptos"
      ref="modalCriteriosCryptos"
    />

    <component
      :is="ModalCriteriosImovirtual"
      ref="modalCriteriosImovirtual"
      :tipos-imovel="tiposImovel"
      :tipos-negocio="tiposNegocio"
    />

    <component
      :is="ModalCriteriosEgo"
      ref="modalCriteriosEgo"
      :tipos-imovel="tiposImovel"
      :tipos-negocio="tiposNegocio"
    />

    <component
      :is="ModalCriteriosIdealista"
      ref="modalCriteriosIdealista"
      :tipos-imovel="tiposImovel"
      :tipos-negocio="tiposNegocio"
      :iso="iso"
    />

    <component
      :is="ModalCriteriosListglobally"
      ref="modalCriteriosListglobally"
      :tipos-imovel="tiposImovel"
      :tipos-negocio="tiposNegocio"
    />

    <component
      :is="ModalCriteriosInmovilla"
      ref="modalCriteriosInmovilla"
      :tipos-imovel="tiposImovel"
      :tipos-negocio="tiposNegocio"
    />

    <component
      :is="ModalCriteriosCasayes"
      ref="modalCriteriosCasayes"
      :tipos-imovel="tiposImovel"
      :tipos-negocio="tiposNegocio"
      :iso="iso"
    />

  </div>
</template>

<script>
import store from '@/store'
import {
  BModal, BRow, BCol, BLink,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BLink,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    iso: {
      type: String,
      default: () => '',
    },
    configApp: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      tiposImovel: '',
      tiposNegocio: '',
      criteriosLuxury: [],
    }
  },
  computed: {
    ...mapGetters('listings_form', ['form_data', 'load_data_geral', 'portals', 'formModeView']),
    ...mapGetters('auth', ['currentUser']),
    isDark() {
      return this.skin.value === 'dark'
    },
    showButtonSave: {
      get() {
        let bShow = true
        if ((typeof this.form_data.sw012s01 !== 'undefined' && this.form_data.sw012s01 !== null && this.form_data.sw012s01 !== ''
            && typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null && Number(this.form_data.sw012s64.sw018s01) === 5
            && typeof this.form_data.sw012s70 !== 'undefined' && this.form_data.sw012s70 !== null && Number(this.form_data.sw012s70) === 1
            && (typeof this.form_data.sw012s261 === 'undefined' || this.form_data.sw012s261 === null || this.form_data.sw012s261 !== '')) || (typeof this.form_data.sw012s01 === 'undefined' || this.form_data.sw012s01 === null || this.form_data.sw012s01 !== '')) {
          bShow = true
        }

        if (typeof this.form_data.sw012s01 !== 'undefined' && this.form_data.sw012s01 !== null && this.form_data.sw012s01 !== ''
            && typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null && Number(this.form_data.sw012s64.sw018s01) === 5
            && (typeof this.form_data.sw012s70 === 'undefined' || this.form_data.sw012s70 === null || this.form_data.sw012s70 === '' || Number(this.form_data.sw012s70) === 0)
            && (typeof this.form_data.sw012s261 === 'undefined' || this.form_data.sw012s261 === null || this.form_data.sw012s261 !== '')) {
          bShow = true
        }

        if (typeof this.form_data.sw012s01 !== 'undefined' && this.form_data.sw012s01 !== null && this.form_data.sw012s01 !== ''
            && typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null && ([1, 2].includes(Number(this.form_data.sw012s64.sw018s01)) || (Number(this.form_data.sw012s64.sw018s01) === 3 && Number(this.load_data_geral.diasVendido) <= 45))) {
          bShow = true
        }

        if (bShow) {
          if ((typeof this.form_data.sw012s01 === 'undefined' || this.form_data.sw012s01 === null || this.form_data.sw012s01 === '')
              || (typeof this.form_data.sw012s72 !== 'undefined' && this.form_data.sw012s72 !== null && this.form_data.sw012s72 === this.currentUser.idHUB && [5, 9, 10, 13, 6, 11, 12].includes(Number(this.currentUser.id_role)))
              || (typeof this.form_data.sw012s72 !== 'undefined' && this.form_data.sw012s72 !== null && this.form_data.sw012s72 === this.currentUser.idHUB && typeof this.form_data.sw012s04 !== 'undefined' && this.form_data.sw012s04 !== null && this.form_data.sw012s04 === atob(this.currentUser.id))
              || (typeof this.form_data.sw012s72 !== 'undefined' && this.form_data.sw012s72 !== null && this.form_data.sw012s72 === this.currentUser.idHUB && typeof this.currentUser.extras.idLeader !== 'undefined' && this.currentUser.extras.idLeader === this.form_data.sw012s04 && Object.values(this.currentUser.extras.perm).includes('add_ang_C'))) {
            return true
          }
        }

        return false
      },
    },
    showUrlWebsite: {
      get() {
        if ((typeof this.form_data.sw012s372 !== 'undefined' && this.form_data.sw012s372 !== null && Number(this.form_data.sw012s372) === 1) && this.load_data_geral.urlWebsite !== ''
            && (typeof this.form_data.sw012s371 !== 'undefined' && this.form_data.sw012s371 !== null)) {
          return true
        }
        return false
      },
    },
    showUrlLuxury: {
      get() {
        if ((typeof this.form_data.sw012s375 !== 'undefined' && this.form_data.sw012s375 !== null && Number(this.form_data.sw012s375) === 1) && this.load_data_geral.urlWebsiteLuxury !== ''
            && (typeof this.form_data.sw012s371 !== 'undefined' && this.form_data.sw012s371 !== null)) {
          return true
        }
        return false
      },
    },
    showUrlImovirtual: {
      get() {
        if ((typeof this.form_data.sw012s382 !== 'undefined' && this.form_data.sw012s382 !== null && Number(this.form_data.sw012s382) === 1) && this.load_data_geral.urlWebsiteImovirtual !== ''
            && (typeof this.form_data.sw012s371 !== 'undefined' && this.form_data.sw012s371 !== null)) {
          return true
        }
        return false
      },
    },
    showUrlListglobally: {
      get() {
        if ((typeof this.form_data.sw012s531 !== 'undefined' && this.form_data.sw012s531 !== null && Number(this.form_data.sw012s531) === 1) && this.load_data_geral.urlWebsiteListglobally !== ''
            && (typeof this.form_data.sw012s371 !== 'undefined' && this.form_data.sw012s371 !== null)) {
          return true
        }
        return false
      },
    },
    showUrlCryptos: {
      get() {
        if ((typeof this.form_data.sw012s540 !== 'undefined' && this.form_data.sw012s540 !== null && Number(this.form_data.sw012s540) === 1) && this.load_data_geral.urlWebsiteCryptos !== ''
            && (typeof this.form_data.sw012s371 !== 'undefined' && this.form_data.sw012s371 !== null)) {
          return true
        }
        return false
      },
    },
    showUrlCasayes: {
      get() {
        if ((typeof this.form_data.sw012s546 !== 'undefined' && this.form_data.sw012s546 !== null && Number(this.form_data.sw012s546) === 1) && this.load_data_geral.urlWebsiteCasayes !== ''
            && (typeof this.form_data.sw012s371 !== 'undefined' && this.form_data.sw012s371 !== null)) {
          return true
        }
        return false
      },
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataPortais.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataPortais.hide()
    },
    async confirmNextModal(evt) {
      evt.preventDefault()
      this.resolvePromise(true)
    },
    async confirmSaveModal(evt) {
      evt.preventDefault()

      await store.dispatch('listings_form/changeWebsiteStatus').then(response => {
        if (response.error !== 1) {
          this.showMsgSuccessRequest({ message: response.msg })
          this.resolvePromise(true)
        } else {
          this.showMsgErrorRequest(new Error(response.msg))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async closeModal() {
      this.resolvePromise(false)
    },
    async getCriterios(portal) {
      if (portal === 'website') {
        await this.$refs.modalCriteriosWebsite.showModal()
      } else if (portal === 'luxury') {
        if (this.iso === 'PT') {
          await store.dispatch('listings_form/getCriteriosLuxury').then(async response => {
            if (response.error !== 1) {
              this.criteriosLuxury = response.data

              this.$refs.modalCriteriosLuxury.showModal().then(async result => {
                if (result === true) {
                  this.criteriosLuxury = []
                }
              })
            }
          }).catch(error => {
            throw error
          })
        } else {
          await this.$refs.modalCriteriosLuxury.showModal()
        }
      } else if (portal === 'cryptos') {
        await this.$refs.modalCriteriosCryptos.showModal()
      } else if (portal === 'imovirtual') {
        await store.dispatch('listings_form/getCriteriosImovirtual').then(async response => {
          if (response.error !== 1) {
            this.tiposImovel = response.data.tiposImovel
            this.tiposNegocio = response.data.tiposNegocio

            this.$refs.modalCriteriosImovirtual.showModal().then(async result => {
              if (result === true) {
                this.tiposImovel = ''
                this.tiposNegocio = ''
              }
            })
          }
        }).catch(error => {
          throw error
        })
      } else if (portal === 'ego') {
        await store.dispatch('listings_form/getCriteriosEgo').then(async response => {
          if (response.error !== 1) {
            this.tiposImovel = response.data.tiposImovel
            this.tiposNegocio = response.data.tiposNegocio

            this.$refs.modalCriteriosEgo.showModal().then(async result => {
              if (result === true) {
                this.tiposImovel = ''
                this.tiposNegocio = ''
              }
            })
          }
        }).catch(error => {
          throw error
        })
      } else if (portal === 'idealista') {
        await store.dispatch('listings_form/getCriteriosIdealista').then(async response => {
          if (response.error !== 1) {
            this.tiposImovel = response.data.tiposImovel
            this.tiposNegocio = response.data.tiposNegocio

            this.$refs.modalCriteriosIdealista.showModal().then(async result => {
              if (result === true) {
                this.tiposImovel = ''
                this.tiposNegocio = ''
              }
            })
          }
        }).catch(error => {
          throw error
        })
      } else if (portal === 'listglobally') {
        await store.dispatch('listings_form/getCriteriosListGlobally').then(async response => {
          if (response.error !== 1) {
            this.tiposImovel = response.data.tiposImovel
            this.tiposNegocio = response.data.tiposNegocio

            this.$refs.modalCriteriosListglobally.showModal().then(async result => {
              if (result === true) {
                this.tiposImovel = ''
                this.tiposNegocio = ''
              }
            })
          }
        }).catch(error => {
          throw error
        })
      } else if (portal === 'inmovilla') {
        await store.dispatch('listings_form/getCriteriosInmovilla').then(async response => {
          if (response.error !== 1) {
            this.tiposImovel = response.data.tiposImovel
            this.tiposNegocio = response.data.tiposNegocio

            this.$refs.modalCriteriosInmovilla.showModal().then(async result => {
              if (result === true) {
                this.tiposImovel = ''
                this.tiposNegocio = ''
              }
            })
          }
        }).catch(error => {
          throw error
        })
      } else if (portal === 'casayes') {
        await store.dispatch('listings_form/getCriteriosCasayes').then(async response => {
          if (response.error !== 1) {
            this.tiposImovel = response.data.tiposImovel
            this.tiposNegocio = response.data.tiposNegocio

            await this.$refs.modalCriteriosCasayes.showModal().then(async result => {
              if (result === true) {
                this.tiposImovel = ''
                this.tiposNegocio = ''
              }
            })
          }
        }).catch(error => {
          throw error
        })
      }
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const blockWebsite = () => import('@/views/modules/listings/form/form_data/portais/website.vue')
    const blockLuxury = () => import('@/views/modules/listings/form/form_data/portais/luxury.vue')
    const blockImovirtual = () => import('@/views/modules/listings/form/form_data/portais/imovirtual.vue')
    const blockEgo = () => import('@/views/modules/listings/form/form_data/portais/ego.vue')
    const blockIdealista = () => import('@/views/modules/listings/form/form_data/portais/idealista.vue')
    const blockListglobally = () => import('@/views/modules/listings/form/form_data/portais/listglobally.vue')
    const blockInmovilla = () => import('@/views/modules/listings/form/form_data/portais/inmovilla.vue')
    const blockCryptos = () => import('@/views/modules/listings/form/form_data/portais/crypto.vue')
    const blockCasaYes = () => import('@/views/modules/listings/form/form_data/portais/casayes.vue')

    const ModalCriteriosWebsite = () => import(/* webpackChunkName: "listing-new-modal-criterios-website" */ /* webpackPrefetch: true */ './criteriosWebsite.vue')
    const ModalCriteriosLuxury = () => import(/* webpackChunkName: "listing-new-modal-criterios-luxury" */ /* webpackPrefetch: true */ './criteriosLuxury.vue')
    const ModalCriteriosImovirtual = () => import(/* webpackChunkName: "listing-new-modal-criterios-imovirtual" */ /* webpackPrefetch: true */ './criteriosImovirtual.vue')
    const ModalCriteriosEgo = () => import(/* webpackChunkName: "listing-new-modal-criterios-ego" */ /* webpackPrefetch: true */ './criteriosEgo.vue')
    const ModalCriteriosIdealista = () => import(/* webpackChunkName: "listing-new-modal-criterios-idealista" */ /* webpackPrefetch: true */ './criteriosIdealista_.vue')
    const ModalCriteriosListglobally = () => import(/* webpackChunkName: "listing-new-modal-criterios-listglobally" */ /* webpackPrefetch: true */ './criteriosListglobally.vue')
    const ModalCriteriosInmovilla = () => import(/* webpackChunkName: "listing-new-modal-criterios-inmovilla" */ /* webpackPrefetch: true */ './criteriosInmovilla.vue')
    const ModalCriteriosCryptos = () => import(/* webpackChunkName: "listing-new-modal-criterios-cryptos" */ /* webpackPrefetch: true */ './criteriosCryptos.vue')
    const ModalCriteriosCasayes = () => import(/* webpackChunkName: "listing-new-modal-criterios-casayes" */ /* webpackPrefetch: true */ './criteriosCasayes.vue')

    return {
      skin,
      blockWebsite,
      blockLuxury,
      blockImovirtual,
      blockEgo,
      blockIdealista,
      blockListglobally,
      blockInmovilla,
      blockCryptos,
      blockCasaYes,

      ModalCriteriosWebsite,
      ModalCriteriosLuxury,
      ModalCriteriosImovirtual,
      ModalCriteriosEgo,
      ModalCriteriosIdealista,
      ModalCriteriosListglobally,
      ModalCriteriosInmovilla,
      ModalCriteriosCryptos,
      ModalCriteriosCasayes,
    }
  },
}
</script>
